import _ from 'lodash';
import React, { useContext, useState, useEffect, useRef } from "react";

import APIRequest from "../Services/shared/APIRequest";
import AsyncDebounce from "../Helpers/Debounce";
import BuildPickerItems from "../Constants/ArchNemesisItems";
import {plant} from "lodash/seq";

const BuildPickerItemProviderContext = React.createContext(null);

const debounce = new AsyncDebounce();

const BuildPickerItemProvider = ({ children }) => {
    const [ buildPickerInformation, updateBuildPickerInformation ] = useState({});
    const [ buildPickerItemsUpdatedAt, updateBuildPickerItemsUpdatedAt ] = useState();
    const [ buildPickerItemLoadingError, updateBuildPickerItemLoadingError ] = useState();
    const [ isShuffling, updateIsShuffling ] = useState(false)

    const getBuildPickerInformation = async () => {
        try {
            // if (!_.isEmpty(buildPickerItemsUpdatedAt) && new Date() - buildPickerItemsUpdatedAt < 1000 * 60 * 10) return buildPickerItems
            const buildPickerInformation = await APIRequest.get('/api/buildPickerInformation');
            console.log(buildPickerInformation)
            updateBuildPickerItemLoadingError();
            updateBuildPickerInformation(buildPickerInformation);
            updateBuildPickerItemsUpdatedAt(new Date());
            return buildPickerInformation
        } catch(err) {
            updateBuildPickerItemLoadingError(err)
        }
    }

    const createPlayer = async (player) => {
        try {
            const buildPickerInformation = await APIRequest.post(`/api/player`, player);
            await getBuildPickerInformation()
        } catch(err) {
            console.log(err)
        }
    }

    const deletePlayer = async (id) => {
        try {
            const buildPickerInformation = await APIRequest.get(`/api/deletePlayer/${id}`);
            await getBuildPickerInformation()
        } catch(err) {
            console.log(err)
        }
    }

    const createBuild = async (build) => {
        try {
            const buildPickerInformation = await APIRequest.post(`/api/build`, build);
            await getBuildPickerInformation()
        } catch(err) {
            console.log(err)
        }
    }

    const deleteBuild = async (id) => {
        try {
            const buildPickerInformation = await APIRequest.get(`/api/deleteBuild/${id}`);
            await getBuildPickerInformation()
        } catch(err) {
            console.log(err)
        }
    }

    const shuffleBuilds = (numShuffles = 30, totalShuffles) => {
        if (!isShuffling) updateIsShuffling(true);

        setTimeout(() => {
            if (numShuffles === 0) {
                let builds = _.shuffle(buildPickerInformation.builds);
                let players = _.shuffle(buildPickerInformation.players);
                if (_.find(players, { isBrennan: 'true' }) && _.find(builds, { isIceTrap: 'true' })) {
                    let brenIndex = _.findIndex(players, { isBrennan: 'true' });
                    let iceTrapIndex = _.findIndex(builds, { isIceTrap: 'true' });

                    if (!(_.size(players) >= _.size(builds))) {
                        let iceTrap = builds[iceTrapIndex];
                        let movingBuild = builds[brenIndex];
                        builds[brenIndex] = iceTrap;
                        builds[iceTrapIndex] = movingBuild;
                    } else {
                        let bren = players[brenIndex];
                        let movingPlayer = players[iceTrapIndex];
                        players[iceTrapIndex] = bren;
                        players[brenIndex] = movingPlayer
                    }


                    setTimeout(() => {
                        updateBuildPickerInformation({
                            numShuffles,
                            players,
                            builds
                        })
                        updateIsShuffling(false)
                    }, 1000)
                } else {
                    updateIsShuffling(false)
                }

                return;
            } else {
                updateBuildPickerInformation({
                    numShuffles,
                    players: _.shuffle(buildPickerInformation.players),
                    builds: _.shuffle(buildPickerInformation.builds),
                })
                shuffleBuilds(numShuffles - 1, totalShuffles ? totalShuffles : numShuffles)
            }
        }, totalShuffles ? _.min([1000, (100 + (30 * (totalShuffles - numShuffles)))]) : 0)
    }

    const saveOrder = async () => {
        await APIRequest.post(`/api/saveOrder`, buildPickerInformation);
    }

    return (
        <BuildPickerItemProviderContext.Provider
            value={{
                buildPickerInformation,
                buildPickerItemLoadingError,
                getBuildPickerInformation: async (args) => debounce.debounce(args, getBuildPickerInformation),
                createPlayer,
                deletePlayer,
                createBuild,
                deleteBuild,
                shuffleBuilds,
                saveOrder,
                isShuffling,
            }}
        >
            {children}
        </BuildPickerItemProviderContext.Provider>
    );
};

const useBuildPickerItemProvider = () => {
    const Event = useContext(BuildPickerItemProviderContext);
    if (Event == null) {
        throw new Error("useBuildPickerItemProvider() called outside of a BuildPickerItemProvider?"); // an alert is not placed because this is an error for the developer not the user
    }
    return Event;
};

export { BuildPickerItemProvider, useBuildPickerItemProvider };

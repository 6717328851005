import _ from 'lodash';
import React, { useContext, useState, useEffect, useRef } from "react";

import APIRequest from "../Services/shared/APIRequest";
import AsyncDebounce from "../Helpers/Debounce";
import ArchNemesisItems from "../Constants/ArchNemesisItems";

const ArchNemesisItemProviderContext = React.createContext(null);

const debounce = new AsyncDebounce();

const ArchNemesisItemProvider = ({ children }) => {
    const [ archNemesisItems, updateArchNemesisItems ] = useState([]);
    const [ archNemesisItemsUpdatedAt, updateArchNemesisItemsUpdatedAt ] = useState();
    const [ archNemesisItemLoadingError, updateArchNemesisItemLoadingError ] = useState();

    const getArchNemesisItems = async () => {
        try {
            if (!_.isEmpty(archNemesisItemsUpdatedAt) && new Date() - archNemesisItemsUpdatedAt < 1000 * 60 * 10) return archNemesisItems

            const archNemesisItems = _.cloneDeep(ArchNemesisItems.items);
            updateArchNemesisItemLoadingError();
            updateArchNemesisItems(archNemesisItems);
            updateArchNemesisItemsUpdatedAt(new Date());

            return archNemesisItems
        } catch(err) {
            updateArchNemesisItemLoadingError(err)
        }
    }

    return (
        <ArchNemesisItemProviderContext.Provider
            value={{
                getArchNemesisItems: async (args) => debounce.debounce(args, getArchNemesisItems),
                archNemesisItems,
                archNemesisItemLoadingError
            }}
        >
            {children}
        </ArchNemesisItemProviderContext.Provider>
    );
};

const useArchNemesisItemProvider = () => {
    const Event = useContext(ArchNemesisItemProviderContext);
    if (Event == null) {
        throw new Error("useArchNemesisItemProvider() called outside of a ArchNemesisItemProvider?"); // an alert is not placed because this is an error for the developer not the user
    }
    return Event;
};

export { ArchNemesisItemProvider, useArchNemesisItemProvider };

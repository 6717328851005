///////////////////////////////////////
//  External Imports
///////////////////////////////////////
import React, { useContext, useState, useEffect } from "react";
import {
    onAuthStateChanged,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    signOut
} from "firebase/auth";
import APIRequest from "../Services/shared/APIRequest";

///////////////////////////////////////
//  Internal Imports
///////////////////////////////////////

// Create a new Context object that will be provided to descendants of
// the AuthProvider.
const AuthContext = React.createContext(null);

// The AuthProvider is responsible for user management and provides the
// AuthContext value to its descendants. Components under an AuthProvider can
// use the useAuth() hook to access the auth value.

const AuthProvider = (props) => {
    const [ user, setUser ] = useState();
    const [ accountInformation, setAccountInformation ] = useState();
    const [ initializing, setInitializing ] = useState(true);

    const {
        auth,
        children
    } = props

    useEffect(() => {
        return onAuthStateChanged(auth, async (user) => {
            if (user) {
                const token = await auth.currentUser.getIdToken();

                let accountInformation = await APIRequest.post('/auth/getAccountInformation', { user }, { headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }})

                setAccountInformation(accountInformation)

                // const docSnap = await getDoc(Collections.USERS, user.uid);
                //
                // if (!docSnap.exists()) {
                //     let newUser = new UserInfoSchema({
                //         id: user.uid,
                //         email: user.email,
                //     });
                //
                //     await setDoc(Collections.USERS, user.uid, newUser.toJSON());
                // }
                //
                // if (!user.emailVerified) {
                //     sendEmailVerification(auth.currentUser).then(() => {});
                // }
                setUser(user)
            }
            setInitializing(false)
        })
    }, []);

    const authenticatedPostRequest = async (url, body) => {
        try {
            const token = await auth.currentUser.getIdToken();

            let result = await APIRequest.post(url, body, { headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }})

            return result
        } catch(err) {
            throw err
        }
    }

    const signIn = ({email, password}) => {
        return signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                console.log('Logged In')
            })
            .catch((error) => {
                console.error(error)
                return error
            });
    }

    const createAccount = async ({accessCode, email, password}) => {
        if (accessCode !== 'SOAR') throw new Error('Incorrect Access Code');

        return createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
            })
            .catch((error) => {
                console.error(error)
                return error
            });
    }

    const resendEmail = () => {
        sendEmailVerification(auth.currentUser)
            .then(() => {
            });
    }

    const logout = (callbackFn) => {
        signOut(auth).then(() => {
            setUser(undefined)
            if (callbackFn) {
                callbackFn()
            }
        });
    }

    return (
        <AuthContext.Provider
            value={{
                user,
                accountInformation,
                initializing,
                signIn,
                logout,
                createAccount,
                resendEmail,
                authenticatedPostRequest
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = () => {
    const auth = useContext(AuthContext);
    if (auth == null) {
        throw new Error("useAuth() called outside of a AuthProvider?");
    }
    return auth;
};

export { AuthProvider, useAuth };

export default AuthProvider

import _ from 'lodash';
import React, { useContext, useState, useEffect, useRef } from "react";

import APIRequest from "../Services/shared/APIRequest";
import AsyncDebounce from "../Helpers/Debounce";

const StoryProviderContext = React.createContext(null);

const debounce = new AsyncDebounce();

const StoryProvider = ({ children }) => {
    const [ story, updateStory ] = useState();
    const [ storys, updateStorys ] = useState([]);
    const [ storysUpdatedAt, updateStorysUpdatedAt ] = useState();
    const [ storyLoadingError, updateStoryLoadingError ] = useState();

    const getStory = async (storyId) => {
        try {
            const story = await APIRequest.get(`/api/story/${storyId}`);
            updateStoryLoadingError();
            updateStory(story);
            return story
        } catch(err) {
            updateStoryLoadingError(err)
        }
    }

    const getStorys = async () => {
        try {
            if (!_.isEmpty(storysUpdatedAt) && new Date() - storysUpdatedAt < 1000 * 60 * 10) return storys
            const storys = await APIRequest.get('/api/storys');
            updateStoryLoadingError();
            updateStorys(storys);
            updateStorysUpdatedAt(new Date());
            return storys
        } catch(err) {
            updateStoryLoadingError(err)
        }
    }

    const getStorySignedURL = async (fileName) => {
        try {
            const { signedURL } = await APIRequest.get(`/api/storys/signedURL/${fileName}`);
            return signedURL;
        } catch(err) {
            // TODO: Handle error
        }
    }

    return (
        <StoryProviderContext.Provider
            value={{
                getStorys: async (args) => debounce.debounce(args, getStorys),
                getStorySignedURL,
                storys,
                storyLoadingError,
                getStory: async (args) => debounce.debounce(args, getStory),
                story
            }}
        >
            {children}
        </StoryProviderContext.Provider>
    );
};

const useStoryProvider = () => {
    const Event = useContext(StoryProviderContext);
    if (Event == null) {
        throw new Error("useStoryProvider() called outside of a StoryProvider?"); // an alert is not placed because this is an error for the developer not the user
    }
    return Event;
};

export { StoryProvider, useStoryProvider };

module.exports = {
    BASE: {
        key: 'BASE',
        title: 'Base',
        description: 'Base item type (Can drop from monsters)',
    },
    LEVEL2: {
        key: 'LEVEL2',
        title: 'Level 2',
        description: 'Made using two base types',
    },
    LEVEL3: {
        key: 'LEVEL3',
        title: 'Level 3',
        description: 'Made using three level 2 or base types',
    },
    LEVEL4: {
        key: 'LEVEL4',
        title: 'Level 4',
        description: 'Made using multiple level 3 or 2 types',
    },
}

import _ from 'lodash';
import React, { } from "react";
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    group: {
        display: 'flex',
        flexDirection: 'row'
    },
    groupLabel: {

    }
}));

export default useStyles;

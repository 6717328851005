import _ from 'lodash';
import React, { useContext, useState, useEffect, useRef } from "react";

import APIRequest from "../Services/shared/APIRequest";
import AsyncDebounce from "../Helpers/Debounce";
import {useAuth} from "./AuthenticationProvider";

const ResourceProviderContext = React.createContext(null);

const debounce = new AsyncDebounce();

const ResourceProvider = ({ children }) => {
    const { authenticatedPostRequest } = useAuth();
    const [ resources, updateResources ] = useState([]);
    const [ resourcesUpdatedAt, updateResourcesUpdatedAt ] = useState();
    const [ resourceLoadingError, updateResourceLoadingError ] = useState();


    const getSignedUploadURL = async (resource, fileName) => {
        try {
            const { signedUploadURL } = await authenticatedPostRequest(`/api/resources/${resource._id}/getSignedUploadURL`, { fileName });

            return signedUploadURL
        } catch(err) {
            console.log(err);
            throw err;
        }
    }
    const updateExistingResource = async (resource) => {
        try {
            const updatedResource = await authenticatedPostRequest(`/api/resources/${resource._id}`, resource);
            await getResources({ ignoreCache: true });

            return updatedResource
        } catch(err) {
            console.log(err);
            throw err;
        }
    }

    const createResource = async (resource) => {
        try {
            const newResource = await authenticatedPostRequest('/api/resources', resource);
            await getResources({ ignoreCache: true });

            return newResource
        } catch(err) {
            console.log(err);
            throw err;
        }
    }
    const getResources = async ({ ignoreCache } = {}) => {
        try {
            if (!ignoreCache && !_.isEmpty(resourcesUpdatedAt) && new Date() - resourcesUpdatedAt < 1000 * 60 * 10) return resources
            const resources = await APIRequest.get('/api/resources');
            updateResourceLoadingError();
            updateResources(resources);
            updateResourcesUpdatedAt(new Date());
            return resources
        } catch(err) {
            updateResourceLoadingError(err)
        }
    }

    const getResourceSignedURL = async (fileName) => {
        try {
            const { signedURL } = await APIRequest.get(`/api/resources/signedURL/${fileName}`);
            return signedURL;
        } catch(err) {
            // TODO: Handle error
        }
    }

    return (
        <ResourceProviderContext.Provider
            value={{
                getSignedUploadURL,
                updateExistingResource,
                createResource,

                getResources: async (args) => debounce.debounce(args, getResources),
                getResourceSignedURL,
                resources,
                resourceLoadingError
            }}
        >
            {children}
        </ResourceProviderContext.Provider>
    );
};

const useResourceProvider = () => {
    const Event = useContext(ResourceProviderContext);
    if (Event == null) {
        throw new Error("useResourceProvider() called outside of a ResourceProvider?"); // an alert is not placed because this is an error for the developer not the user
    }
    return Event;
};

export { ResourceProvider, useResourceProvider };

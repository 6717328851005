import _ from 'lodash';
import React from 'react';

import {
    TextField
} from '@material-ui/core';


const ListComponent = (props) => {
    const {
        // FormikProps
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,

        // Form Props
        onChange,
        onBlur,
        formLocation,

        field

    } = props;
    const {
        label,
        location,
    } = field

    return (
        <TextField
            label={label}
            value={_.get(values, location)}
            onChange={onChange}
            onBlur={onBlur}
            variant={'outlined'}
        />
    )
}

export default ListComponent

import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import {RoutingProvider} from "./Providers/RoutingProvider";
import ApplicationUI from "./Scenes/ApplicationUI";
import Theme from './Constants/Theme'
import {
    BrowserRouter as Router,
} from "react-router-dom";

import {ScreenSizeProvider} from "./Providers/ScreenSizeProvider";
import {ResourceProvider} from "./Providers/ResourceProvider";
import {StoryProvider} from "./Providers/StoryProvider";
import {ArchNemesisItemProvider} from "./Providers/ArchNemesisItemProvider";
import {BuildPickerItemProvider} from "./Providers/BuildPickerProvider";

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import AuthenticationProvider from "./Providers/AuthenticationProvider";

const FirebaseConfig = {
    apiKey: "AIzaSyDfvOttJdACE573k6GZKT4wjzu1SZmfDsY",
    authDomain: "montessoar.firebaseapp.com",
    projectId: "montessoar",
    storageBucket: "montessoar.appspot.com",
    messagingSenderId: "1086013905424",
    appId: "1:1086013905424:web:5d0fe88739258d86298895",
    measurementId: "G-VY46CEBBJ2"
};

const FirebaseApp = initializeApp(FirebaseConfig);
const FirebaseAuth = getAuth(FirebaseApp);

function App() {
  return (
      <ThemeProvider theme={Theme}>
          <AuthenticationProvider app={FirebaseApp} auth={FirebaseAuth}>
              <Router>
                  <RoutingProvider>
                      <ScreenSizeProvider>
                          <ResourceProvider>
                              <ArchNemesisItemProvider>
                                  <BuildPickerItemProvider>
                                      <StoryProvider>
                                          <ApplicationUI />
                                      </StoryProvider>
                                  </BuildPickerItemProvider>
                              </ArchNemesisItemProvider>
                          </ResourceProvider>
                      </ScreenSizeProvider>
                  </RoutingProvider>
              </Router>
          </AuthenticationProvider>
      </ThemeProvider>
  );
}
export default App;

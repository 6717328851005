import _ from 'lodash';
import React, {useEffect, useState} from "react";
import clsx from "clsx";

import {
    Divider,
    Typography,
    Card,
    CardActions,
    CardMedia,
    CardContent,
    Grid,
} from "@material-ui/core";

import Visibility from "@material-ui/icons/Visibility"

import {useScreenSize} from "../../Providers/ScreenSizeProvider";
import { useStoryProvider } from "../../Providers/StoryProvider";

import StoryTypes from '../../Constants/StoryTypes';

import useSceneStyles from "../../Styles/Scene";
import useStoryStyles from "../../Styles/Story";

import Loading from "../../Components/Loading";
import Error from "../../Components/Error";
import AsyncButton from "../../Components/AsyncButton";
import MovingIcon from "../../Components/Paralax/MovingIcon";
import Form, {FieldTypes} from "../../Components/Form/Form";

import StoryElementTypes from "../../Constants/StoryElementTypes"
import Paragraph from "./Shared/Paragraph"
import Images from "./Shared/Images";

const debounceFunction = _.debounce((functionArgs, functionToCall) => functionToCall(functionArgs), 300)

const Story = (props) => {
    const { match } = props
    const storyId = _.get(match, 'params.id');
    const { isSmall } = useScreenSize();
    const { getStory, story, storyLoadingError } = useStoryProvider();
    const [ isLoading, updateIsLoading ] = useState();
    const storyClasses = useStoryStyles();

    useEffect(async () => {
        if (_.isEmpty(story)) {
            updateIsLoading(true)
            await getStory(storyId);
        }
        updateIsLoading(false)
    }, [])


    return (
        <div style={{ width: '100%' }}>
            <div className={storyClasses.storyContainer}>
                <Loading fontSize="large" isLoading={isLoading} height={66} width={66} moveAcrossContainer={true}/>
                <Error err={storyLoadingError && { message: "Cannot get story" }}/>
                {!_.isEmpty(story) &&
                    <React.Fragment>
                        <Typography variant={'h2'}>{_.get(story, 'title')}</Typography>
                        <Typography variant={'subtitle1'}>{_.get(story, 'description')}</Typography>
                        {_.map(_.get(story, 'elements'), (element) => {
                            if (_.get(element, 'elementType') === StoryElementTypes.PARAGRAPH.key) {
                                return (<Paragraph element={element}/>)
                            }
                            if (_.get(element, 'elementType') === StoryElementTypes.IMAGES.key) {
                                return (<Images element={element}/>)
                            }
                        })}
                    </React.Fragment>
                }
            </div>
        </div>
    )
}

export default Story

import _ from 'lodash';
import React, {useState} from 'react';
import clsx from 'clsx'

import {
    TextField,
    Typography
} from '@material-ui/core';

import useStyles from '../../../Styles/Formik'

import Field from './Field';


const GroupComponent = (props) => {
    const {
        // FormikProps
        formikProps,

        // field Props
        style = {},
        label,
        fieldLocation,
        fields,

    } = props;
    const classes = useStyles()

    return (
        <React.Fragment>
            {label &&
            <Typography className={clsx(classes.groupLabel)} variant={'h6'}>
                {label}
            </Typography>
            }
            <div className={clsx(classes.group)} style={style}>
                {_.map(fields, (field, index) => {
                    return (
                        <Field
                            key={`${fieldLocation}-${_.get(field.location)}-${index}`}
                            field={field}
                            formikProps={formikProps}
                            fieldLocation={fieldLocation}
                        />
                    )
                })}
            </div>
        </React.Fragment>
    )
}

export default GroupComponent

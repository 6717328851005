import _ from 'lodash';
import ArchNemesisItemTypes from "./ArchNemesisItemTypes";

const SENTINEL = {
    key: "SENTINEL",
    title: "Sentinel",
    itemType: ArchNemesisItemTypes.BASE.key
}

const JUGGERNAUT = {
    key: "JUGGERNAUT",
    title: "Juggernaut",
    itemType: ArchNemesisItemTypes.BASE.key,
}

const CONSECRATOR = {
    key: "CONSECRATOR",
    title: "Consecrator",
    itemType: ArchNemesisItemTypes.BASE.key,
}

const BOMBADIER = {
    key: "BOMBADIER",
    title: "Bombadier",
    itemType: ArchNemesisItemTypes.BASE.key,
}

const OVERCHARGED = {
    key: "OVERCHARGED",
    title: "Overcharged",
    itemType: ArchNemesisItemTypes.BASE.key,
}

const FRENZIED = {
    key: "FRENZIED",
    title: "Frenzied",
    itemType: ArchNemesisItemTypes.BASE.key,
}

const BERSERKER = {
    key: "BERSERKER",
    title: "Berserker",
    itemType: ArchNemesisItemTypes.BASE.key,
}

const GARGANTUAN = {
    key: "GARGANTUAN",
    title: "Gargantuan",
    itemType: ArchNemesisItemTypes.BASE.key,
}

const FROSTWEAVER = {
    key: "FROSTWEAVER",
    title: "Frostweaver",
    itemType: ArchNemesisItemTypes.BASE.key,
}

const FLAMEWEAVER = {
    key: "FLAMEWEAVER",
    title: "Flameweaver",
    itemType: ArchNemesisItemTypes.BASE.key,
}

const STORMWEAVER = {
    key: "STORMWEAVER",
    title: "Stormweaver",
    itemType: ArchNemesisItemTypes.BASE.key,
}

const CHAOSWEAVER = {
    key: "CHAOSWEAVER",
    title: "Chaosweaver",
    itemType: ArchNemesisItemTypes.BASE.key,
}

const HASTED = {
    key: "HASTED",
    title: "Hasted",
    itemType: ArchNemesisItemTypes.BASE.key,
}

const INCENDIARY = {
    key: "INCENDIARY",
    title: "Incendiary",
    itemType: ArchNemesisItemTypes.BASE.key,
}

const BONEBREAKER = {
    key: "BONEBREAKER",
    title: "Bonebreaker",
    itemType: ArchNemesisItemTypes.BASE.key,
}

const ECHOIST = {
    key: "ECHOIST",
    title: "Echoist",
    itemType: ArchNemesisItemTypes.BASE.key,
}

const SOUL_CONDUIT = {
    key: "SOUL_CONDUIT",
    title: "Soul Conduit",
    itemType: ArchNemesisItemTypes.BASE.key,
}

const DYNAMO = {
    key: "DYNAMO",
    title: "Dynamo",
    itemType: ArchNemesisItemTypes.BASE.key,
}

const VAMPIRIC = {
    key: "VAMPIRIC",
    title: "Vampiric",
    itemType: ArchNemesisItemTypes.BASE.key,
}

const BLOODLETTER = {
    key: "BLOODLETTER",
    title: "Bloodletter",
    itemType: ArchNemesisItemTypes.BASE.key,
}

const PERMAFROST = {
    key: "PERMAFROST",
    title: "Permafrost",
    itemType: ArchNemesisItemTypes.BASE.key,
}

const ARCANE_BUFFER = {
    key: "ARCANE_BUFFER",
    title: "Arcane Buffer",
    itemType: ArchNemesisItemTypes.BASE.key,
}

const INVULNERABLE = {
    key: "INVULNERABLE",
    title: "Invulnerable",
    itemType: ArchNemesisItemTypes.LEVEL2.key,
    recipeItems: [
        CONSECRATOR.key,
        JUGGERNAUT.key,
        SENTINEL.key
    ]
}

const NECROMANCER = {
    key: "NECROMANCER",
    title: "Necromancer",
    itemType: ArchNemesisItemTypes.LEVEL2.key,
    recipeItems: [
        BOMBADIER.key,
        OVERCHARGED.key,
    ]
}

const EXECUTIONER = {
    key: "EXECUTIONER",
    title: "Executioner",
    itemType: ArchNemesisItemTypes.LEVEL2.key,
    recipeItems: [
        FRENZIED.key,
        BERSERKER.key,
    ]
}

const FROST_STRIDER = {
    key: "FROST_STRIDER",
    title: "Frost Strider",
    itemType: ArchNemesisItemTypes.LEVEL2.key,
    recipeItems: [
        FROSTWEAVER.key,
        HASTED.key,
    ]
}

const MAGMA_BARRIER = {
    key: "MAGMA_BARRIER",
    title: "Magma Barrier",
    itemType: ArchNemesisItemTypes.LEVEL2.key,
    recipeItems: [
        INCENDIARY.key,
        BONEBREAKER.key,
    ]
}

const MIRROR_IMAGE = {
    key: "MIRROR_IMAGE",
    title: "Mirror Image",
    itemType: ArchNemesisItemTypes.LEVEL2.key,
    recipeItems: [
        ECHOIST.key,
        SOUL_CONDUIT.key
    ]
}

const MANA_SIPHONER = {
    key: "MANA_SIPHONER",
    title: "Mana Siphoner",
    itemType: ArchNemesisItemTypes.LEVEL2.key,
    recipeItems: [
        CONSECRATOR.key,
        DYNAMO.key
    ]
}

const CORRUPTER = {
    key: "CORRUPTER",
    title: "Corrupter",
    itemType: ArchNemesisItemTypes.LEVEL2.key,
    recipeItems: [
        BLOODLETTER.key,
        CHAOSWEAVER.key
    ]
}

const FLAMESTRIDER = {
    key: "FLAMESTRIDER",
    title: "Flame strider",
    itemType: ArchNemesisItemTypes.LEVEL2.key,
    recipeItems: [
        FLAMEWEAVER.key,
        HASTED.key
    ]
}

const REJUVINATING = {
    key: "REJUVINATING",
    title: "Rejuvinating",
    itemType: ArchNemesisItemTypes.LEVEL2.key,
    recipeItems: [
        GARGANTUAN.key,
        VAMPIRIC.key
    ]
}

const ICE_PRISON = {
    key: "ICE_PRISON",
    title: "Ice Prison",
    itemType: ArchNemesisItemTypes.LEVEL2.key,
    recipeItems: [
        PERMAFROST.key,
        SENTINEL.key
    ]
}

const STORM_STRIDER = {
    key: "STORM_STRIDER",
    title: "Storm Strider",
    itemType: ArchNemesisItemTypes.LEVEL2.key,
    recipeItems: [
        STORMWEAVER.key,
        HASTED.key
    ]
}

const HERALDING_MINIONS = {
    key: "HERALDING_MINIONS",
    title: "Heralding Minions",
    itemType: ArchNemesisItemTypes.LEVEL2.key,
    recipeItems: [
        DYNAMO.key,
        ARCANE_BUFFER.key
    ]
}

const EMPOWERING_MINIONS = {
    key: "EMPOWERING_MINIONS",
    title: "Empowering Minions",
    itemType: ArchNemesisItemTypes.LEVEL3.key,
    recipeItems: [
        NECROMANCER.key,
        EXECUTIONER.key,
        GARGANTUAN.key,
    ]
}

const LUNARIS_TOUCHED = {
    key: "LUNARIS_TOUCHED",
    title: "Lunaris Touched",
    itemType: ArchNemesisItemTypes.LEVEL3.key,
    recipeItems: [
        INVULNERABLE.key,
        EMPOWERING_MINIONS.key,
        FROST_STRIDER.key,
    ]
}

const SOLARIS_TOUCHED = {
    key: "SOLARIS_TOUCHED",
    title: "Solaris Touched",
    itemType: ArchNemesisItemTypes.LEVEL3.key,
    recipeItems: [
        INVULNERABLE.key,
        EMPOWERING_MINIONS.key,
        MAGMA_BARRIER.key,
    ]
}

const TUKOHAMA_TOUCHED = {
    key: "TUKOHAMA_TOUCHED",
    title: "Tukohama Touched",
    itemType: ArchNemesisItemTypes.LEVEL3.key,
    recipeItems: [
        EXECUTIONER.key,
        MAGMA_BARRIER.key,
        BONEBREAKER.key,
    ]
}

const ABBERATH_TOUCHED = {
    key: "ABBERATH_TOUCHED",
    title: "Abberath Touched",
    itemType: ArchNemesisItemTypes.LEVEL3.key,
    recipeItems: [
        FLAMESTRIDER.key,
        REJUVINATING.key,
        FRENZIED.key,
    ]
}

const CORPSE_DETONATOR = {
    key: "CORPSE_DETONATOR",
    title: "Corpse Detonator",
    itemType: ArchNemesisItemTypes.LEVEL3.key,
    recipeItems: [
        NECROMANCER.key,
        INCENDIARY.key,
    ]
}

const INNOCENCE_TOUCHED = {
    key: "INNOCENCE_TOUCHED",
    title: "Innocence Touched",
    itemType: ArchNemesisItemTypes.LEVEL4.key,
    recipeItems: [
        LUNARIS_TOUCHED.key,
        SOLARIS_TOUCHED.key,
        MIRROR_IMAGE.key,
        MANA_SIPHONER.key,
    ]
}

const KITAVA_TOUCHED = {
    key: 'KITAVA_TOUCHED',
    title: 'Kitava Touched',
    itemType: ArchNemesisItemTypes.LEVEL4.key,
    recipeItems: [
        TUKOHAMA_TOUCHED.key,
        ABBERATH_TOUCHED.key,
        CORPSE_DETONATOR.key,
        CORRUPTER.key,
    ]
}

const BRINE_KING_TOUCHED = {
    key: 'BRINE_KING_TOUCHED',
    title: 'Brine King Touched',
    itemType: ArchNemesisItemTypes.LEVEL4.key,
    recipeItems: [
        ICE_PRISON.key,
        STORM_STRIDER.key,
        HERALDING_MINIONS.key,
    ]
}

export default {
    items: [
        // Base
        SENTINEL,
        JUGGERNAUT,
        CONSECRATOR,
        BOMBADIER,
        OVERCHARGED,
        FRENZIED,
        BERSERKER,
        GARGANTUAN,
        FROSTWEAVER,
        FLAMEWEAVER,
        STORMWEAVER,
        CHAOSWEAVER,
        HASTED,
        INCENDIARY,
        BONEBREAKER,
        ECHOIST,
        SOUL_CONDUIT,
        DYNAMO,
        VAMPIRIC,
        BLOODLETTER,
        PERMAFROST,
        ARCANE_BUFFER,

        // Level 2
        INVULNERABLE,
        NECROMANCER,
        EXECUTIONER,
        FROST_STRIDER,
        MAGMA_BARRIER,
        MIRROR_IMAGE,
        MANA_SIPHONER,
        CORRUPTER,
        FLAMESTRIDER,
        REJUVINATING,
        ICE_PRISON,
        STORM_STRIDER,
        HERALDING_MINIONS,

        // Level 3
        EMPOWERING_MINIONS,
        LUNARIS_TOUCHED,
        SOLARIS_TOUCHED,
        TUKOHAMA_TOUCHED,
        CORPSE_DETONATOR,

        // Level 4
        INNOCENCE_TOUCHED,
        KITAVA_TOUCHED,
        BRINE_KING_TOUCHED,
        ABBERATH_TOUCHED,
    ]
}

import _ from 'lodash';
import React, { } from "react";
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    storyContainer: {
        // width: '100%',
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
    },

}));

export default useStyles;

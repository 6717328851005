import _ from 'lodash';
import React from "react";
import Typography from '@material-ui/core/Typography'
import clsx from "clsx";

import useStyles  from "../../../Styles/Scene";

const Paragraph = (props) => {
    const {
        element
    } = props;
    const {
        title,
        text
    } = element;
    const classes = useStyles();

    if (!element) return (<React.Fragment />)

    return (
        <div className={clsx(classes.column)} style={{ margin: 10 }}>
            {title && <Typography variant={'h4'}>{title}</Typography>}
            {text && <Typography variant={'body1'}>{text}</Typography>}
        </div>
    );
};

export default Paragraph;

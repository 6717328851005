import _ from 'lodash';
import React from "react";
import Typography from '@material-ui/core/Typography'

import useStyles  from "../Styles/Scene";
import clsx from "clsx";

const Loading = (props) => {
    const {
        err
    } = props
    const classes = useStyles();

    if (!err) return (<React.Fragment />)

    return (
        <div className={clsx(classes.row)} style={{ justifyContent: 'center', margin: 10 }}>
            <Typography variant={'subtitle1'} color={'secondary'}>
                Error:
            </Typography>
            <Typography variant={'subtitle1'}>
                {_.get(err, 'message')}
            </Typography>
        </div>
    );
};

export default Loading;

import _ from 'lodash';
import React, { useEffect, useState } from "react";
import clsx from 'clsx';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress'

import useStyles  from "../Styles/AsyncButton";
import Loading from "./Loading";

const AsyncButton = (props) => {
    const {
        onClick,
        disabled,
        loading,
        color = 'secondary',
        LeftIcon,
        RightIcon,
        TitleIcon,
        label,
        key,
        variant = 'contained',
        id,
        ...otherProps
    } = props
    const classes = useStyles();
    const [ isLoading, updateIsLoading ] = useState(false)

    return (
        <div id={id} className={classes.wrapper} key={key}>
            <Button
                {...otherProps}
                variant={variant}
                color={color}
                onClick={async () => {
                    updateIsLoading(true)
                    try {
                        await onClick()
                    } catch(err) {
                        // TODO: Report err
                    }
                    updateIsLoading(false)
                }}
                disabled={disabled || loading || isLoading}
            >
                <React.Fragment>
                    { LeftIcon && <LeftIcon className={classes.leftIcon} /> }
                    { label && label }
                    { TitleIcon && <TitleIcon /> }
                    { RightIcon && <RightIcon className={classes.rightIcon} /> }
                </React.Fragment>
                <Loading className={classes.buttonProgress} isLoading={loading || isLoading} moveAcrossContainer={true} />
            </Button>
        </div>
    );
};

export default AsyncButton;

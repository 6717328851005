import _ from 'lodash';
import React, {useState} from "react";
import PLX from 'react-plx';

import {useScreenSize} from "../../Providers/ScreenSizeProvider";

const createRangeArray = (start, end, steps) => {
    let randomStart = _.min([start, end]);
    let randomEnd = _.max([start, end]);
    let randomSortedIntsInArray = _.sortBy(_.times(steps, () => _.random(randomStart + 1, randomEnd)));

    if (end < start) {
        randomSortedIntsInArray = _.reverse(randomSortedIntsInArray)
    }

    let randomRangesArray = [];

    for (let i = 0; i < _.size(randomSortedIntsInArray); i += 1) {
        if (i === 0) {
            randomRangesArray.push([ start, randomSortedIntsInArray[i] ]);
        } else if (i < _.size(randomSortedIntsInArray) - 1) {
            randomRangesArray.push([ randomSortedIntsInArray[i - 1], randomSortedIntsInArray[i] ]);
        } else {
            randomRangesArray.push([ randomSortedIntsInArray[i], end ]);
        }
    }

    return randomRangesArray
}

const MovingIcon = (props) => {
    const {
        style = {},
        offset = 0,
        steps = 15,
        startTop,
        endTop,
        startLeft,
        endLeft,
        duration,
        disabled,
        IconComponent
    } = props;
    const { isSmall } = useScreenSize();

    const stepDuration = duration / steps;
    const topProperties = _.map(createRangeArray(startTop, endTop, steps), ([ start, end ]) => {
        return {
            startValue: start,
            endValue: end,
            property: 'top'
        }
    })

    const leftProperties = _.map(createRangeArray(startLeft, endLeft, steps), ([ start, end ]) => {
        return {
            startValue: start,
            endValue: end,
            property: 'left'
        }
    })

    const parallaxData = _.times(steps, (index) => {
        const start = stepDuration * index + offset;
        return {
            start,
            end: start + stepDuration,
            properties: [
                topProperties[index],
                leftProperties[index]
            ]
        }
    })

    return (
            <PLX
                disabled={disabled}
                // parallaxData={_.sortBy([...topAnimations, ...leftAnimations ], 'start')}
                parallaxData={parallaxData}
                style={{
                    position: 'absolute',
                    top: startTop,
                    left: startLeft,
                    flex: 1,
                    zIndex: 2
                }}
            >
                <IconComponent />
            </PLX>
    )
};

export default MovingIcon

import _ from 'lodash';
import React, {useState} from "react";
import {
    Switch,
    Route,
    useRouteMatch,
    useHistory
} from "react-router-dom";
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import useStyles, { drawerWidth } from "../Styles/ApplicationUI";

import Routes from "../Constants/Routes";

const NavigationItem = (props) => {
    const {
        key,
        title,
        navTitle,
        path,
        NavIcon,
        routes,
        isDefaultOpen = false,
        subRouteIndex = 0,
        onClick,
        index
    } = props;
    const theme = useTheme();
    const classes = useStyles();
    const history = useHistory();
    const match = useRouteMatch(path) || {};
    const [ isOpen, updateIsOpen ] = useState(isDefaultOpen);

    if (!_.isEmpty(routes)) {
        return (
            <React.Fragment>
                <ListItem className={clsx({ [classes.navigationItemSelected]: match.isExact })}
                          button
                          key={title}
                          onClick={() => updateIsOpen(!isOpen)}
                >
                    <ListItemIcon ><NavIcon /></ListItemIcon>
                    <ListItemText primary={navTitle || title} />
                    {isOpen ? <ExpandMore /> : <ChevronRightIcon />}
                </ListItem>
                {isOpen && _.map(routes, (route, index) => {
                    return (
                        <NavigationItem key={`${title}-${index}`} {...route} subRouteIndex={subRouteIndex + 1}/>
                    )
                })}
            </React.Fragment>
        )
    }

    return (
        <ListItem key={key || title}
                  className={clsx({ [classes.navigationItemSelected]: match.isExact })}
                  style={subRouteIndex ? { paddingLeft: 20 * subRouteIndex } : {}}
                  button
                  onClick={() => history.push(path)}
        >
            <ListItemIcon ><NavIcon /></ListItemIcon>
            <ListItemText primary={navTitle || title} />
        </ListItem>
    )
}

const NavigationDrawer = ({ open, setOpen }) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div className={classes.drawerHeader}>
                <img src="/MontessoarIcon.png" alt="image" height={52} width={177}/>
                <IconButton onClick={() => setOpen(false)}>
                    {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
            </div>
            <Divider />
            <List>
                {_.map(Routes, (route, index) => (
                    <NavigationItem key={`route-${index}`} index={index} {...route } />
                ))}
            </List>
        </Drawer>
    );
};

export default NavigationDrawer;

import {useAuth} from "../../Providers/AuthenticationProvider";
import React, {useRef, useState} from 'react'
import { Routes } from "../../Constants/Routes";
import {Box, Button, Container, CssBaseline, Grid, Link, Slide, TextField, Typography} from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles';
import {useScreenSize} from "../../Providers/ScreenSizeProvider";
import AsyncButton from "../../Components/AsyncButton";
import {useHistory} from "react-router-dom";

const Splash = (props) => {
    const theme = useTheme();

    return (
        <Box
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100%',
                margin: theme.spacing(2),
                flex: 1
            }}
        >
            <Typography variant={'h2'} color={"primary"} style={{ fontWeight: 600 }}>Climb to New Possibilities</Typography>
            <Typography variant={'h4'} style={{ fontWeight: 700, marginBottom: theme.spacing(8) }}>For Educators, Institutions, and Individuals</Typography>
            <Typography variant={'body1'} style={{ fontWeight: 500 }}>
                Level Up is a comprehensive application that provides a variety of compliance, regulation, and ethics trainings as well as opportunities for workforce skilling/re-skilling. Our personalized and customized digital credential management (DCM) system allows you to create, send, and track professional development and regulatory compliance and training. You can select from a variety of modules to create stackable badges across topics and domains. Select your training and your approach then begin your journey to “Level Up!”                </Typography>
        </Box>
    )
}

export default function LandingComponent(props) {
    const {
        user,
        signIn,
        createAccount
    } = useAuth()

    const theme = useTheme();
    const history = useHistory();
    const { isXS, isSmall } = useScreenSize();
    const containerRef = useRef(null);
    const [isSignInVisible, updateIsSignInVisible] = useState(false);
    const [isCreateAccountVisible, updateIsCreateAccountVisible] = useState(false);
    const hideOverviewText = isXS || isSmall;

    if (user) {
        history.push(Routes.HOME.path)
    }

    const [errorMessage, setErrorMessage] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [emailErrorMessage, setEmailErrorMessage] = useState('')
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('')
    const [accessCode, setAccessCode] = useState('')

    const attemptToCreateUser = async () => {
        let attemptCall = true
        if (!email) {
            setEmailErrorMessage('Email must be set')
            attemptCall = false
        }
        if (password?.length < 8) {
            setPasswordErrorMessage('Password must be at least 8 characters')
            attemptCall = false
        }
        if (attemptCall) {
            console.log('creating account')
            let result = await createAccount({accessCode, email, password})
            if (result?.code === 'auth/invalid-email') {
                setPasswordErrorMessage('')
                setEmailErrorMessage('A valid email must be used')
            }
        }
    }

    const attemptToSignIn = async () => {
        let result = await signIn({email, password})
        if (result?.code === 'auth/wrong-password') {
            setErrorMessage('Incorrect password')
        } else if (result?.code === 'auth/user-not-found') {
            setErrorMessage('An account with this email is not registered')
        } else if (result?.code === 'auth/invalid-email') {
            setErrorMessage('Invalid email provided')
        }
    }

    let iconSize = isXS ? '300px' : '400px'

    return (
        <Box
            style={{
                backgroundPositionX: 'center',
                backgroundImage: 'url(Background.png)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                minHeight: 'calc(100% - 54px)'
            }}
        >
            <CssBaseline />
            <Box
                style={{
                    flexDirection: 'row',
                    overflow: 'hidden',
                    display: 'flex',
                    minHeight: '80vh',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
                ref={containerRef}
            >
                {containerRef.current &&
                    <Slide direction={isSignInVisible ? 'right' : 'left'} in={isSignInVisible} container={containerRef.current} mountOnEnter={true} unmountOnExit={true}>
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                margin: theme.spacing(2),
                                padding: theme.spacing(4),
                                borderRadius: 10,
                                backgroundColor: theme.palette.background.main,
                                maxWidth: 500
                            }}
                            {...props}
                        >
                            <img
                                src="/MontessoarIcon.png"
                                alt="image"
                                height={100}
                                width={300}
                            />
                            <Box style={{ mt: 1 }}>
                                <Typography textAlign={'left'} style={{ width: '100%', fontWeight: 600, marginTop: 20 }} color={'primary'}>Sign In</Typography>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    value={email}
                                    onChange={(event) => {
                                        setEmail(event.target.value);
                                    }}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    value={password}
                                    onChange={(event) => {
                                        setPassword(event.target.value);
                                    }}
                                />
                                <AsyncButton
                                    fullWidth
                                    variant="contained"
                                    style={{ marginTop: 3, marginBottom: 2 }}
                                    onClick={attemptToSignIn}
                                    label={"Sign In"}
                                >

                                </AsyncButton>
                                <Typography align={'center'}>
                                    <AsyncButton
                                        onClick={() => {
                                            updateIsSignInVisible(false)
                                            setTimeout(() => {
                                                updateIsCreateAccountVisible(true)
                                            }, 500)
                                        }}
                                        label={"New User? Create Account"}
                                    >
                                    </AsyncButton>
                                </Typography>
                                {errorMessage?.length > 0 &&
                                    <Typography align={'center'} component="h1" variant="subtitle2" color={theme.palette.error.main} style={{ marginTop: 2 }}>
                                        {errorMessage}
                                    </Typography>
                                }
                            </Box>
                        </Box>
                    </Slide>
                }
                {/*{!hideOverviewText && <Splash />}*/}
                <Slide direction={!isSignInVisible && !isCreateAccountVisible ? 'up' : 'down'}  in={!isSignInVisible && !isCreateAccountVisible} unmountOnExit={true} container={containerRef.current} mountOnEnter={true}>
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: theme.spacing(2),
                            padding: theme.spacing(4),
                            borderRadius: 10,
                            backgroundColor: theme.palette.background.main,
                            maxWidth: 500
                        }}
                    >
                        <img
                            src="/MontessoarIcon.png"
                            alt="image"
                            height={100}
                            width={300}
                        />                        <Box style={{ mt: 4 }}>
                            <AsyncButton
                                fullWidth
                                variant="contained"
                                // style={{ marginTop: 3, marginBottom: 2 }}
                                onClick={() => updateIsSignInVisible(true)}
                                label={"Sign In"}
                            >

                            </AsyncButton>
                            <AsyncButton
                                fullWidth
                                variant="outlined"
                                style={{ marginTop: 3, marginBottom: 2 }}
                                onClick={() => updateIsCreateAccountVisible(true)}
                                color={'secondary'}
                                label={"Create Account"}
                            >

                            </AsyncButton>
                        </Box>
                    </Box>
                </Slide>
                {containerRef.current &&
                    <Slide direction={isCreateAccountVisible ? 'left' : 'right'} in={isCreateAccountVisible} container={containerRef.current} mountOnEnter={true} unmountOnExit={true}>
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                margin: theme.spacing(2),
                                padding: theme.spacing(4),
                                borderRadius: 10,
                                backgroundColor: theme.palette.background.main,
                                maxWidth: 500,
                            }}
                        >
                            <img
                                src="/MontessoarIcon.png"
                                alt="image"
                                height={100}
                                width={300}
                            />
                            <Box style={{ mt: 1 }}>
                                <Typography textAlign={'left'} style={{ width: '100%', fontWeight: 600 }} color={'primary'}>Sign Up</Typography>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="accessCode"
                                    label="Access Code"
                                    name="accessCode"
                                    autoFocus
                                    value={accessCode}
                                    onChange={(event) => {
                                        setAccessCode(event.target.value);
                                    }}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    value={email}
                                    onChange={(event) => {
                                        setEmail(event.target.value);
                                    }}
                                    error={emailErrorMessage?.length > 0 ? true : null}
                                    helperText={emailErrorMessage}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    value={password}
                                    onChange={(event) => {
                                        setPassword(event.target.value);
                                    }}
                                    error={passwordErrorMessage?.length > 0 ? true : null}
                                    helperText={passwordErrorMessage}
                                />
                                <AsyncButton
                                    fullWidth
                                    variant="contained"
                                    style={{ marginTop: 3, marginBottom: 2 }}
                                    disabled={!accessCode || !email || !password}
                                    onClick={attemptToCreateUser}
                                    label={"Sign Up"}
                                >

                                </AsyncButton>
                                <Typography align={'center'}>
                                    <AsyncButton
                                        onClick={() => {
                                            updateIsCreateAccountVisible(false)
                                            setTimeout(() => {
                                                updateIsSignInVisible(true)
                                            }, 500)
                                        }}
                                        label={"Existing Account? Sign In"}
                                    >

                                    </AsyncButton>
                                </Typography>
                            </Box>
                        </Box>
                    </Slide>
                }
            </Box>
        </Box>
    )
}

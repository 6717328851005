import _ from 'lodash';
import React from 'react';
import { Formik } from 'formik';

import { populateFieldComponents, FieldTypes as AvailableFieldTypes } from './Helpers/FieldComponentHelpers'

const FormComponent = (props) => {
    const {
        style,
        formikProps,
        fields
        /* and other goodies */
    } = props;

    const {
        handleSubmit,
        onBlur
    } = formikProps;

    return (
        <form onSubmit={handleSubmit} onBlur={onBlur}>
            <AvailableFieldTypes.GROUP.FieldComponent
                style={style}
                formikProps={formikProps}
                fields={fields}
            />
        </form>
    )
}

const Form = (props) => {
    const {
        // FormikProps
        initialValues = {},
        style = {},
        fields,
        onSubmit,
        onBlur,
        setRef
    } = props;

    return (
        <Formik
            setRef={setRef}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
                onSubmit && onSubmit(values)
            }}
            onBlur={(values, { setSubmitting }) => {
                onBlur && onBlur(values)
            }}
        >
            {
                (formikProps) => {
                    return (<FormComponent style={style} fields={populateFieldComponents(fields)} formikProps={formikProps} />)
                }
            }
        </Formik>
    )
}

export const FieldTypes = AvailableFieldTypes;

export default Form

module.exports = {
    MATH: {
        key: 'MATH',
        title: 'Math',
        description: 'Math',
        NavIcon: 'Exposure'
    },
    BIOLOGY: {
        key: 'SCIENCE',
        title: 'Science',
        description: 'Science',
        NavIcon: 'Eco'
    },
    LITERACY: {
        key: 'LITERACY',
        title: 'Literacy',
        description: 'Literacy',
        NavIcon: 'MenuBook'
    },
    NOMENCLATURE_CARDS: {
        key: 'NOMENCLATURE_CARDS',
        title: 'Nomenclature Cards',
        description: 'Nomenclature Cards',
        NavIcon: 'CollectionsBookmark'
    },
    SOCIAL_STUDIES: {
        key: 'SOCIAL_STUDIES',
        title: 'Social Studies',
        description: 'Social Studies',
        NavIcon: 'Public'
    },
    WRITING_TEMPLATES: {
        key: 'WRITING_TEMPLATES',
        title: 'Writing Papers/Templates',
        description: 'Writing Papers/Templates',
        NavIcon: 'Public'
    }
}

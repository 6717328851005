import _ from 'lodash';
import React from 'react';

import {
    FormGroup,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';


const TextFieldComponent = (props) => {
    const {
        // FormikProps
        formikProps,

        // Form Props
        fullLocation,

        // Field Props,
        style,
        variant,

        label,
        value,
        name,
        onHandleChange,
        onHandleBlur

    } = props;

    return (
        <FormGroup>
            <FormControlLabel control={<Checkbox value={value} onClick={() => onHandleChange(!value)} />} label={label} />
        </FormGroup>
    )
}

export default TextFieldComponent

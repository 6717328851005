import _ from 'lodash';
import React, { lazy } from 'react'
import Dashboard from '@material-ui/icons/Dashboard';
import Info from '@material-ui/icons/Info';
import EmojiPeople from '@material-ui/icons/EmojiPeople';
import Exposure from '@material-ui/icons/Exposure'
import Eco from '@material-ui/icons/Eco'
import MenuBook from '@material-ui/icons/MenuBook'
import CollectionsBookmark from '@material-ui/icons/CollectionsBookmark'
import Public from '@material-ui/icons/Public'
import School from '@material-ui/icons/School'
import CollectionsBook from '@material-ui/icons/CollectionsBookmark'

import ResourceTypes from './ResourceTypes'
import StoryTypes from './StoryTypes'
import AllStorys from "../Scenes/Storys/Storys";
import Story from "../Scenes/Storys/Story";
import SignInComponent from "../Scenes/SignIn/SignIn";

const Home = lazy(() => import('../Scenes/Home/Home'))
const Mission = lazy(() => import('../Scenes/Home/Pages/Mission'))
const Overview = lazy(() => import('../Scenes/Home/Pages/Overview'))
const Archnemisis = lazy(() => import('../Scenes/Archnemisis/Archnemesis'))
const BuildPicker = lazy(() => import('../Scenes/BuildPicker/BuildPicker'))
const AllResources = lazy(() => import('../Scenes/Resources/AllResources'));

const ICONS = {
    Exposure,
    Eco,
    MenuBook,
    CollectionsBookmark,
    Public,
    School,
}

const generateResourcesRoute = (resourceType) => {
    return {
        key: `RESOURCE_${resourceType.key}`,
        title: `${resourceType.title}`,
        path: `/montesources/${resourceType.key}`,
        appBarTitle: `MonteSources: ${resourceType.title}`,
        RightIcon: () => (<img src={"/MontessoarBirdIconWhite.png"} height={30} width={30}/>),
        NavIcon: ICONS[resourceType.NavIcon],
        Scene: (props) => (<AllResources filters={{ resourceType: resourceType.key }} {...props} />)
    }
}

const generateStoriesRoute = (storyType) => {
    return {
        key: `STORY_${storyType.key}`,
        title: `${storyType.title}`,
        path: `/montestories/${storyType.key}`,
        appBarTitle: `MonteStories: ${storyType.title}`,
        RightIcon: () => (<img src={"/MontessoarBirdIconWhite.png"} height={30} width={30}/>),
        NavIcon: ICONS[storyType.NavIcon],
        Scene: (props) => (<AllStorys filters={{ storyType: storyType.key }} {...props} />)
    }
}

const RESOURCE_ROUTES = _.reduce(_.values(ResourceTypes), (routes, resourceType) => {
    let resourceRoute = generateResourcesRoute(resourceType);
    routes[resourceRoute.key] = resourceRoute;
    return routes;
}, {})

const STORY_ROUTES = _.reduce(_.values(StoryTypes), (routes, storyType) => {
    let storyRoute = generateStoriesRoute(storyType);
    routes[storyRoute.key] = storyRoute;
    return routes;
}, {})

export const Routes = {
    HOME: {
        key: 'HOME',
        title: 'Montessoar',
        appBarTitle: '',
        navTitle: 'Home',
        path: '/',
        Scene: Home,
        RightIcon: () => (<img src={"/MontessoarBirdIconWhite.png"} height={30} width={30}/>),
        NavIcon: () => (<img src={"/MontessoarBirdIcon.png"} height={30} width={30}/>),
        exact: true
    },
    MONTESOURCES: {
        key: 'MONTESOURCES',
        title: 'MonteSources',
        navTitle: 'MonteSources',
        path: '/montesources',
        RightIcon: () => (<img src={"/MontessoarBirdIconWhite.png"} height={30} width={30}/>),
        NavIcon: (props = {}) => (<Dashboard style={{ fontSize: 30 }} {...props} />),
        Scene: AllResources,
        isDefaultOpen: true,
        exact: true,
        routes: RESOURCE_ROUTES
    },
    ...RESOURCE_ROUTES,
    MONTESTORIES: {
        key: 'MONTESTORIES',
        title: 'MonteStories',
        navTitle: 'MonteStories',
        path: '/montestories',
        RightIcon: () => (<img src={"/MontessoarBirdIconWhite.png"} height={30} width={30}/>),
        NavIcon: (props = {}) => (<CollectionsBook style={{ fontSize: 30 }} {...props} />),
        Scene: AllStorys,
        isDefaultOpen: true,
        exact: true,
        routes: STORY_ROUTES
    },
    ...STORY_ROUTES,
    MONTESTORY: {
        key: 'MONTESTORY',
        title: 'MonteStory',
        path: '/montestory/:id',
        RightIcon: () => (<img src={"/MontessoarBirdIconWhite.png"} height={30} width={30}/>),
        Scene: Story,
        exact: false
    },
    MISSION: {
        title: 'Mission',
        path: '/mission',
        RightIcon: () => (<img src={"/MontessoarBirdIconWhite.png"} height={30} width={30}/>),
        NavIcon: (props = {}) => (<EmojiPeople style={{ fontSize: 30 }} {...props} />),
        Scene: Mission
    },
    ABOUT: {
        title: 'About',
        path: '/about',
        RightIcon: () => (<img src={"/MontessoarBirdIconWhite.png"} height={30} width={30}/>),
        NavIcon: (props = {}) => (<Info style={{ fontSize: 30 }} {...props} />),
        Scene: Overview
    },
    SIGN_IN: {
        title: 'Sign In',
        path: '/signIn',
        RightIcon: () => (<img src={"/MontessoarBirdIconWhite.png"} height={30} width={30}/>),
        NavIcon: (props = {}) => (<Info style={{ fontSize: 30 }} {...props} />),
        Scene: SignInComponent
    },
    ARCHNEMESIS: {
        title: 'Archnemesis',
        path: '/archnemesis',
        RightIcon: () => (<img src={"/MontessoarBirdIconWhite.png"} height={30} width={30}/>),
        NavIcon: (props = {}) => (<Info style={{ fontSize: 30 }} {...props} />),
        Scene: Archnemisis
    },
    BUILD_PICKER: {
        title: 'Build Picker',
        path: '/buildpicker',
        RightIcon: () => (<img src={"/MontessoarBirdIconWhite.png"} height={30} width={30}/>),
        NavIcon: (props = {}) => (<Info style={{ fontSize: 30 }} {...props} />),
        Scene: BuildPicker
    }
}

const NavigationRoutes = [
    Routes.HOME,
    Routes.MONTESOURCES,
    Routes.MONTESTORIES,
    Routes.MISSION,
    Routes.ABOUT,
    // Routes.SIGN_IN,
];

export const findRoute = (path) => {
    return _.find(Routes, { path })
}

export default NavigationRoutes

import _ from 'lodash';
import React, {useEffect, useState} from "react";
import clsx from "clsx";

import {
    Divider,
    Typography,
    Card,
    CardActions,
    CardMedia,
    CardContent,
    Grid,
} from "@material-ui/core";

import Visibility from "@material-ui/icons/Visibility"

import {useScreenSize} from "../../../Providers/ScreenSizeProvider";
import { useStoryProvider } from "../../../Providers/StoryProvider";

import StoryTypes from '../../../Constants/StoryTypes';

import useSceneStyles from "../../../Styles/Scene";
import useCardStyles from "../../../Styles/Card";

import Loading from "../../../Components/Loading";
import Error from "../../../Components/Error";
import AsyncButton from "../../../Components/AsyncButton";
import MovingIcon from "../../../Components/Paralax/MovingIcon";
import Form, {FieldTypes} from "../../../Components/Form/Form";

const debounceFunction = _.debounce((functionArgs, functionToCall) => functionToCall(functionArgs), 300)

const StorysList = (props) => {
    const {
        filters = {},
        numVisibleStorys,
        isFiltersVisible = true
    } = props
    const { isSmall } = useScreenSize();
    const { getStorys, getStorySignedURL, storys, storyLoadingError } = useStoryProvider();
    const [ isLoading, updateIsLoading ] = useState();
    const cardClasses = useCardStyles();
    const [localFilters, updateLocalFilters] = useState({});
    const debounceUpdateLocalFilters = (filters) => debounceFunction(filters, updateLocalFilters)

    let mergedFilters = _.merge(filters, localFilters);

    useEffect(async () => {
        if (_.isEmpty(storys)) {
            updateIsLoading(true)
            await getStorys(mergedFilters);
        }
        updateIsLoading(false)
    }, [])

    let visibleStorys = numVisibleStorys ? _.slice(storys, 0, numVisibleStorys) : storys;

    if (!_.isEmpty(visibleStorys)) {
        const desiredStoryType = _.get(mergedFilters, 'storyType');
        const desiredTitle = _.get(mergedFilters, 'search');

        visibleStorys = _.filter(visibleStorys, (story) => {
            const storyTypeMatches = (!desiredStoryType || _.includes(story.storyTypes, desiredStoryType) || story.storyType === desiredStoryType);
            const titleMatches = _.isEmpty(desiredTitle) || _.includes(_.lowerCase(story.title), _.lowerCase(desiredTitle))
            return storyTypeMatches && titleMatches
        })
    }

    return (
        <div style={{ width: '100%' }}>
            <div className={cardClasses.gridContainer}>
                {isFiltersVisible && <Form
                    initialValues={mergedFilters}
                    fields={[
                        {
                            label: 'Search Stories',
                            location: 'search',
                            fieldType: FieldTypes.TEXT_FIELD.key,
                            submitOnBlur: true,
                            onHandleChange: (value, location) => {
                                let filtersToUpdate = _.cloneDeep(mergedFilters);
                                _.set(filtersToUpdate, location, value)
                                debounceUpdateLocalFilters(filtersToUpdate)
                            }
                        }
                    ]}
                    onSubmit={(e) => {
                        updateLocalFilters(e)
                    }}
                />}
                <Loading fontSize="large" isLoading={isLoading} height={66} width={66} moveAcrossContainer={true}/>
                <Error err={storyLoadingError && { message: "Cannot get storys" }}/>
                <Grid container spacing={4} justify="center"  direction="row">
                    {_.map(visibleStorys, ({title, storyType, description, fileName, views, _id, previewFileName}) => {
                        const StoryType = StoryTypes[storyType];
                        return (
                            <Grid item xs={12} sm={6} md={3} spacing={1} justify="space-around" alignItems="center"  direction="row">
                                <Card className={clsx(cardClasses.cardContainer)}>
                                    <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant={'h6'}>
                                            {title}
                                        </Typography>
                                        <Typography color={'textSecondary'}>
                                            {_.get(StoryType, 'title', 'Story')}
                                        </Typography>
                                        <Typography variant={'body2'} style={{ flex: 1 }}>
                                            {description}
                                        </Typography>
                                        {previewFileName &&
                                        <CardMedia
                                            className={clsx(cardClasses.cardMedia)}
                                            image={previewFileName}
                                            title={`${title} Preview`}
                                        />
                                        }
                                        <CardActions className={clsx(cardClasses.actionsContainer)}>
                                            <Typography>{views || 'Be the first to '} View{views > 1 && 's'}</Typography>
                                            <AsyncButton
                                                variant={'outline'}
                                                TitleIcon={() => <Visibility fontSize={'large'} color={'secondary'} />}
                                                href={`/montestory/${_id}`}
                                            />
                                        </CardActions>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )
                    })}
                </Grid>
            </div>
        </div>
    )
}

export default StorysList

import _ from 'lodash';
import React, {useEffect, useState} from "react";
import clsx from "clsx";

import {
    Divider,
    Typography,
    Card,
    CardActions,
    CardContent,
    Grid,
} from "@material-ui/core";

import {useScreenSize} from "../../Providers/ScreenSizeProvider";

import useSceneStyles from "../../Styles/Scene";
import StorysList from "../Storys/Shared/StorysList";
import AsyncButton from "../../Components/AsyncButton";
import {useHistory} from "react-router-dom";
import { Routes } from "../../Constants/Routes";

const AllStorys = (props) => {
    const { filters } = props;
    const { isSmall } = useScreenSize();
    const sceneClasses = useSceneStyles();
    const history = useHistory();

    return (
        <React.Fragment>
            <div className={sceneClasses.sceneContainer} style={{ marginTop: 10 }}>
                <StorysList filters={filters} />
            </div>
        </React.Fragment>
    )
}

export default AllStorys

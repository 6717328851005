import _ from 'lodash';
import Field from '../Components/Field';
import Group from '../Components/Group';
import List from '../Components/List';
import TextField from '../Components/TextField';
import Select from '../Components/Select';
import Checkbox from '../Components/Checkbox';

export const FieldTypes = {
    FIELD: {
        key: 'FIELD',
        FieldComponent: Field
    },
    GROUP: {
        key: 'GROUP',
        FieldComponent: Group
    },
    LIST: {
        key: 'LIST',
        FieldComponent: List
    },
    TEXT_FIELD: {
        key: 'TEXT_FIELD',
        FieldComponent: TextField
    },
    SELECT: {
        key: 'SELECT',
        FieldComponent: Select
    },
    CHECKBOX: {
        key: 'CHECKBOX',
        FieldComponent: Checkbox
    }
}

export const populateFieldComponents = (fields) => {
    return _.filter(_.map(fields, (field) => {
        field.fields = populateFieldComponents(field.fields);
        field.FieldComponent = _.get(FieldTypes[field.fieldType], 'FieldComponent');
        if (!field.FieldComponent) return;
        return field
    }));
};

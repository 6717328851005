import _ from 'lodash';
import React, { } from "react";
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    primary: {
        color: theme.palette.primary.main
    },
    secondary: {
        color: theme.palette.secondary.main
    },
    error: {
        color: theme.palette.error.main
    },
    leftIcon: {
        marginRight: theme.spacing(1) || 0,
    },
    rightIcon: {
        marginLeft: theme.spacing(1) || 0,
    },
    iconSmall: {
        fontSize: 20,
    }
}));

export default useStyles;

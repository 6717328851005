import React from 'react'
import { SvgIcon } from "@material-ui/core";

const BirdFlap1 = (props = {}) => {
    return (
        <SvgIcon
            color={'black'}
            fontSize={'large'}
            {...props}
        >
            <title>MontessoarBirdIconFlap1</title>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="iPhone-11-Pro-Max" transform="translate(-51.000000, -735.000000)" stroke="#000000">
                    <g id="MontessoarBirdIconFlap" transform="translate(52.000000, 736.000000)">
                        <path d="M8.78774591,24.005843 C9.15617375,23.4474659 9.5246016,22.8890887 9.89302944,22.3307116" id="Path"></path>
                        <polyline id="Path-2" points="9.56812059 22.7152721 9.56812059 22.9894037 9.56812059 23.0112778 9.56812059 23.0345631 9.56812059 23.0578485 9.56812059 23.0797225 9.56812059 23.1015966 9.56812059 23.1234707 9.56812059 23.1453448 9.56812059 23.1672189 9.56812059 23.1905042 9.56812059 23.2123783 9.56812059 23.2356636 9.56812059 23.2575377 9.59260003 23.2575377 9.59260003 23.280823 9.59260003 23.3041083 9.59260003 23.3259824"></polyline>
                        <path d="M9.79659531,22.8514559 C9.83739437,23.3063428 9.87819342,23.7612296 9.91899248,24.2161165" id="Path-3"></path>
                        <path d="M9.9345703,22.8433413 C10.3073253,23.1868585 10.6800803,23.5303757 11.0528354,23.8738929" id="Path-4"></path>
                        <path d="M9.69051776,23.0719608 C9.68248158,22.0848045 9.67444541,21.0976483 9.66640923,20.110492" id="Path-5"></path>
                        <path d="M12.4800536,24.005843 C12.8484814,23.4474659 13.2169093,22.8890887 13.5853371,22.3307116" id="Path"></path>
                        <polyline id="Path-2" points="13.2604283 22.7152721 13.2604283 22.9894037 13.2604283 23.0112778 13.2604283 23.0345631 13.2604283 23.0578485 13.2604283 23.0797225 13.2604283 23.1015966 13.2604283 23.1234707 13.2604283 23.1453448 13.2604283 23.1672189 13.2604283 23.1905042 13.2604283 23.2123783 13.2604283 23.2356636 13.2604283 23.2575377 13.2849077 23.2575377 13.2849077 23.280823 13.2849077 23.3041083 13.2849077 23.3259824"></polyline>
                        <path d="M13.488903,22.8514559 C13.5297021,23.3063428 13.5705011,23.7612296 13.6113002,24.2161165" id="Path-3"></path>
                        <path d="M13.626878,22.8433413 C13.999633,23.1868585 14.372388,23.5303757 14.745143,23.8738929" id="Path-4"></path>
                        <path d="M13.3828255,23.0719608 C13.3747893,22.0848045 13.3667531,21.0976483 13.3587169,20.110492" id="Path-5"></path>
                        <path d="M1.24066222,13.3573978 L1.24066222,13.3573978 Z" id="Path-6"></path>
                        <path d="M2.67641812,14.1258132 C5.58010369,17.8454086 9.97189788,23.3141741 15.9068104,19.5862196 C18.5194508,17.9451164 22.1349943,11.5316565 21.8369542,6.64686633 C21.7119675,4.59836974 20.8987195,2.81871216 19.0346144,1.77260064 C10.1659001,-3.20440653 11.7496882,3.37817843 8.58409799,8.25910424 C8.12350792,8.96927386 7.56237377,9.64341945 6.85387062,10.2406936 C5.81875998,11.1133007 6.52850826,6.86634621 5.16701508,6.63464503 C4.02440938,6.44019448 6.08041125,9.31708392 5.12695782,9.94680472 C-6.51766593,17.637649 13.0490874,-4.58086013 3.28172776,10.4428525 C2.82266624,11.1489602 1.86093857,7.46968983 1.95612932,8.29495857 L0,7.80420291 C0.222341515,9.73182201 1.67424493,11.3405132 2.51136739,13.1086683 L2.67641812,14.1258132 Z" id="Path-7" fill="#FFFFFF"></path>
                        <ellipse id="Oval" fill="#000000" cx="18.2977942" cy="5.20600913" rx="1" ry="1"></ellipse>
                        <path d="M22.9131788,6.84750006 L23.259319,7.81787592 L22.5670387,7.81787592 L22.9131788,6.84750006 Z" id="Triangle" stroke-width="0.75" fill="#FFFFFF" transform="translate(22.913179, 6.962107) rotate(90.000000) translate(-22.913179, -6.962107) "></path>
                        <path d="M5.32235867,13.886896 C12.7660219,14.1190326 1.89672002,14.2220537 14.5215883,10.8956505 C14.6173818,10.8704108 14.9018802,11.9651024 15.4901954,14.9793115 C16.5752802,20.5387001 8.31685512,18.2128622 5.40577179,14.3702016" id="Path-8"></path>
                    </g>
                </g>
            </g>
        </SvgIcon>
    )
}

export default BirdFlap1

import _ from 'lodash';
import React, { } from "react";
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    activeCard: {
        backgroundColor: theme.palette.background.paper
    },
    deactivatedCard: {
        borderColor: theme.palette.warning.dark,
        borderStyle: 'solid',
        borderWidth: 4
    },
    gridContainer: {
        // width: '100%',
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
    },
    cardContainer: {
        display: 'flex',
        minWidth: 200,
        margin: 10,
        height: '100%'
    },
    cardMedia: {
        height: 0,
        paddingTop: '56.25%'
    },
    actionsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    }
}));

export default useStyles;

import _ from 'lodash';
import React, {useEffect, useState} from "react";
import CircularProgress from '@material-ui/core/CircularProgress'

import useStyles  from "../Styles/Scene";

import BirdFlap1 from "./SVGIcons/BirdFlap1";
import BirdFlap2 from "./SVGIcons/BirdFlap2";

const Loading = (props) => {
    const classes = useStyles();

    const {
        isLoading,
        fontSize = 'medium',
        className = classes.progress,
        moveAcrossContainer,
        width = 26,
        height = 26
    } = props
    const [timingIndex, updateTimingIndex] = useState(0);
    let Icon = timingIndex % 2 === 0 ? BirdFlap1 : BirdFlap2;
    let style = {};

    if (moveAcrossContainer) {
        let timingPercentage = timingIndex * 10;
        style.left = `${timingPercentage}%`
    }

    useEffect(() => {
        if (isLoading) {
            setTimeout(() => {
                if (timingIndex > 8) return updateTimingIndex(1);
                return updateTimingIndex(timingIndex + 1);
            }, 200)
        }
    }, [timingIndex])

    useEffect(() => {
        if (isLoading) {
            updateTimingIndex(timingIndex + 1);
        }
    }, [isLoading]);

    if (!isLoading) return (<React.Fragment />);

    return (
        <Icon
            fontSize={fontSize}
            className={className}
            style={style}
            height={height}
            width={width}
        />
    );
};

export default Loading;

import _ from 'lodash';
import React from 'react';

import { joinLocation } from "../Helpers/LocationHelpers";


const Field = (props) => {
    const {
        key,
        // FormikProps
        formikProps,

        // Form Props
        fieldLocation,

        field,

    } = props;

    const {
        values,
        errors,
        touched,
        handleChange:formHandleChange,
        handleBlur:formHandleBlur,
        handleSubmit,
        isSubmitting,
    } = formikProps

    const {
        label,
        location,
        style = { flex: 1 },
        variant = 'outlined',
        onHandleChange,
        onHandleBlur,
        submitOnBlur,
        fields,
        FieldComponent,
        ...otherProps
    } = field;

    const finalFieldLocation = joinLocation([fieldLocation, location]);

    return (
        <FieldComponent
            key={key}

            // Known Field props
            style={style}
            label={label}
            fieldLocation={finalFieldLocation}
            name={finalFieldLocation}
            variant={variant}
            onHandleChange={(e) => {
                formHandleChange(e);
                onHandleChange && onHandleChange(e.target.value, finalFieldLocation);
            }}
            onHandleBlur={(e, ...props) => {
                formHandleBlur(e, ...props)
                if (submitOnBlur) {
                    setTimeout(() => {
                        handleSubmit(e, ...props)
                    }, 100)
                }
                onHandleBlur && onHandleBlur(e,...props);
            }}
            fields={fields}

            // Calculated
            value={_.get(values, finalFieldLocation)}
            error={_.get(errors, finalFieldLocation)}
            touched={_.get(touched, finalFieldLocation)}

            // Pass through
            field={field}
            formikProps={formikProps}
            {...otherProps}
        />
    )
}

export default Field

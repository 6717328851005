import React from "react";
import _ from "lodash"

const assertHTTPSuccess = (res) => {
    if (res.ok) { return res }

    return res.text()
        .then((responseText) => {
            let error = new Error(responseText || res.statusText);
            error.res = res;
            error.status = res.status;
            throw error
        });
};

let nextFetchId = 0;

class WebAPIRequest {
    constructor(route, {
        method,
        authenticated = true,
        requiresSuccess = true,
        formatResponse = true,
        stringifyBody = true,
        headers,
        body
    }) {
        this.route = route;
        this.method = method;
        this.authenticated = authenticated;
        this.requiresSuccess = requiresSuccess;
        this.formatResponse = formatResponse;
        this.body = body;
        this.headers = headers;
        this.stringifyBody = stringifyBody
    }

    static get(route, options) {
        let request = new WebAPIRequest(route, { method: 'GET', ...options });

        return request.perform(options);
    }

    static head(route, options) {
        let request = new WebAPIRequest(route, { method: 'HEAD', formatResponse: false, ...options });

        return request.perform(options);
    }

    static post(route, body, options) {
        let request = new WebAPIRequest(route, { method: 'POST', body, ...options });

        return request.perform(options);
    }

    static put(route, body, options) {
        let request = new WebAPIRequest(route, { method: 'PUT', body, ...options });

        return request.perform(options);
    }

    static delete(route, options) {
        let request = new WebAPIRequest(route, { method: 'DELETE', formatResponse: false, ...options });

        return request.perform(options);
    }

    static patch(route, options) {
        let request = new WebAPIRequest(route, { method: 'PATCH', formatResponse: false, ...options });

        return request.perform(options);
    }

    static upload(route, files, options) {
        let formData = new FormData();

        _.map(files, (file, index) => {
            formData.append(`files[]`, file, file.name)
        });

        let request = new WebAPIRequest(route, {
            method: 'POST',
            body: formData,
            headers: {
                'Content-Type': null,
                'Accept': '*/*'
            },
            stringifyBody: false,
            ...options
        });

        return request.perform(options);
    }

    getHeaders() {
        let headers = {
            Accept: 'application/json'
        };

        // if (this.authenticated) {
        //     headers['Authorization'] = `Bearer ${window.localStorage.token}`;
        // }
        if (this.body) {
            headers['Content-Type'] = 'application/json';
        }

        if (this.headers) {
            headers = _.merge(headers, this.headers);
            if (this.headers['Content-Type'] === null) {
                _.unset(headers, 'Content-Type')
            }
        }

        return headers;
    }

    getBody() {
        if (this.body) {
            return this.stringifyBody ? JSON.stringify(this.body) : this.body;
        }

        return undefined;
    }

    perform({ retryLogin = true } = {}) {
        if (!this.method) {
            return Promise.reject(new Error("HTTP request method is required"));
        }

        let route = `${this.route}`;
        let requestData = {
            method: this.method,
            headers: this.getHeaders(),
            body: this.getBody()
        };

        this._fetchId = nextFetchId++;
        let request = fetch(route, requestData, this._fetchId);

        if (this.requiresSuccess) {
            request = request
                .then(assertHTTPSuccess);
        }

        return request
            .then((res) => {
                if (!this.formatResponse || res.status === 204) {
                    return res;
                }

                return res.json();
            })
            // .catch((err) => {
            //     if (err.status === 401) {
            //         if (retryLogin) {
            //             return WebAPIRequest.post('/login', { refresh_token: window.localStorage.refreshToken }, { authenticated: false, retryLogin: false })
            //                 .then((res) => {
            //                     window.localStorage.token = res.accessToken;
            //                 })
            //                 .then(() => this.perform({ retryLogin: false }))
            //
            //         }
            //     }
            //
            //     throw err;
            // })
    }
}

export default WebAPIRequest;

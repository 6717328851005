import _ from 'lodash';
import React from 'react';

import {
    TextField
} from '@material-ui/core';


const TextFieldComponent = (props) => {
    const {
        // FormikProps
        formikProps,

        // Form Props
        fullLocation,

        // Field Props,
        style,
        variant,

        label,
        value,
        name,
        onHandleChange,
        rows,
        multiline,
        onHandleBlur

    } = props;

    return (
        <TextField
            style={style}
            label={label}
            value={value}
            name={name}
            onChange={onHandleChange}
            onBlur={onHandleBlur}
            variant={variant}
            rows={rows}
            multiline={multiline}
        />
    )
}

export default TextFieldComponent

import _ from 'lodash';
import React from 'react';

import {
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core';


const TextFieldComponent = (props) => {
    const {
        // FormikProps
        formikProps,

        // Form Props
        fullLocation,

        // Field Props,
        style,
        variant,

        list,
        labelKey,
        valueKey,
        multiple,

        label,
        value = [],
        name,
        onHandleChange,
        onHandleBlur

    } = props;

    return (
        <FormControl fullWidth>
            <InputLabel style={{ marginLeft: 15 }} id={label}>{label}</InputLabel>
            <Select
                multiple={multiple}
                labelId={label}
                style={style}
                label={label}
                value={value ? value : (multiple ? [] : null)}
                name={name}
                onChange={onHandleChange}
                variant={variant}
            >
                {_.map(list, (listItem) => {
                    return (
                        <MenuItem value={listItem[valueKey]}>{listItem[labelKey]}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

export default TextFieldComponent

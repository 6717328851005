module.exports = {
    PARAGRAPH: {
        key: 'PARAGRAPH',
        title: 'Paragraph'
    },
    IMAGES: {
        key: 'IMAGES',
        title: 'Images'
    }
}

import _ from 'lodash';
import React from "react";
import Typography from '@material-ui/core/Typography'
import clsx from "clsx";

import useStyles  from "../../../Styles/Scene";

const Paragraph = (props) => {
    const {
        element
    } = props;
    const {
        images
    } = element;
    const classes = useStyles();

    if (!element) return (<React.Fragment />)

    return (
        <div className={clsx(classes.row)} style={{ margin: 10, justifyContent: 'space-evenly', alignItems: 'center' }}>
            {images && _.map(images, (image) => {
                return (<img style={{ maxHeight: 400, maxWidth: 400 }} src={image} />)
            })}
        </div>
    );
};

export default Paragraph;
